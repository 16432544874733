// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
___CSS_LOADER_EXPORT___.push([module.id, "@import url(https://fonts.googleapis.com/css2?family=Press+Start+2P&display=swap);"]);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "body {\n  margin: 0;\n  font-family: source-code-pro, Menlo, Monaco, Consolas, \"Courier New\",\n    monospace;\n  -webkit-font-smoothing: antialiased;\n  -moz-osx-font-smoothing: grayscale;\n  background-color: black;\n  color: #f7f7f7;\n}\n\n.fintro {\n  font-family: \"Press Start 2P\", cursive;\n}\n\n.link-unstyled {\n  color: #6c757d;\n  text-decoration: none;\n}\n\n.link-unstyled:hover {\n  text-decoration: none;\n  color: #f7f7f7;\n}\n\n.fyellow {\n  color: yellow;\n}\n\nimg {\n  filter: grayscale(100%);\n  -webkit-filter: grayscale(100%);\n}\n\nimg:hover {\n  filter: none;\n  -webkit-filter: grayscale(0%);\n}\n", "",{"version":3,"sources":["webpack://./src/CSS/index.css"],"names":[],"mappings":"AAEA;EACE,SAAS;EACT;aACW;EACX,mCAAmC;EACnC,kCAAkC;EAClC,uBAAuB;EACvB,cAAc;AAChB;;AAEA;EACE,sCAAsC;AACxC;;AAEA;EACE,cAAc;EACd,qBAAqB;AACvB;;AAEA;EACE,qBAAqB;EACrB,cAAc;AAChB;;AAEA;EACE,aAAa;AACf;;AAEA;EACE,uBAAuB;EACvB,+BAA+B;AACjC;;AAEA;EACE,YAAY;EACZ,6BAA6B;AAC/B","sourcesContent":["@import url(\"https://fonts.googleapis.com/css2?family=Press+Start+2P&display=swap\");\n\nbody {\n  margin: 0;\n  font-family: source-code-pro, Menlo, Monaco, Consolas, \"Courier New\",\n    monospace;\n  -webkit-font-smoothing: antialiased;\n  -moz-osx-font-smoothing: grayscale;\n  background-color: black;\n  color: #f7f7f7;\n}\n\n.fintro {\n  font-family: \"Press Start 2P\", cursive;\n}\n\n.link-unstyled {\n  color: #6c757d;\n  text-decoration: none;\n}\n\n.link-unstyled:hover {\n  text-decoration: none;\n  color: #f7f7f7;\n}\n\n.fyellow {\n  color: yellow;\n}\n\nimg {\n  filter: grayscale(100%);\n  -webkit-filter: grayscale(100%);\n}\n\nimg:hover {\n  filter: none;\n  -webkit-filter: grayscale(0%);\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
